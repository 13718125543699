import React from 'react';
import { Container, List, ListItem, ListItemText, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Title from 'components/semantic/Title';

export function AboutPage() {
  return (
    <Container>
      <Title>
        À propos
      </Title>

      <Paper elevation={4} sx={{ mb: 4 }}>
        <List>
          <ListItem>
            <FontAwesomeIcon icon={['fas', 'dice-d20']} />&nbsp;
            <ListItemText disableTypography primary="Cette plate-forme est une initiative de Olivier Leclercq." />
          </ListItem>

          <ListItem>
            <FontAwesomeIcon icon={['fas', 'dice-d20']} />&nbsp;
            <ListItemText disableTypography primary="Pour toutes les demandes, veuillez envoyer un mail à tabletopmeetings.contact@gmail.com." />
          </ListItem>
        </List>
      </Paper>
    </Container>
  );
}
