import styled from "@emotion/styled";

const Subtitle = styled.h2`
  color: #9F2F54;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 2em;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 1.2em;
  }
`;

export default Subtitle;
