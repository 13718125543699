import React from 'react';
import { useSelector } from 'react-redux';

export default function PermissionGuard({
  children,
  permission,
  reverse = false,
}) {
  const permissions = useSelector((state) => state.session.permissions) ?? [];

  const condition = reverse ? !permissions.includes(permission) : permissions.includes(permission);

  return (
    <>
      {condition && children}
    </>
  );
}
