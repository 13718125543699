import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SliderExperience from '../../SliderExperience';
import SliderFreedom from '../../SliderFreedom';
import SliderSeriousness from '../../SliderSeriousness';

export default function EventDialogRegistrationTab({ eventData }) {
  const sliderExperienceValue = [eventData.logisticsOptions.playersExperienceMinimum, eventData.logisticsOptions.playersExperienceMaximum];
  const sliderFreedomValue = [eventData.logisticsOptions.scaleFreedomMinimum, eventData.logisticsOptions.scaleFreedomMaximum];
  const sliderSeriousnessValue = [eventData.logisticsOptions.scaleSeriousnessMinimum, eventData.logisticsOptions.scaleSeriousnessMaximum];

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'user-minus']} />
        </ListItemIcon>
        <ListItemText primary="Joueurs minimum" />
        <Chip label={eventData.logisticsOptions.playersMinimum} variant="outlined" />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'user-plus']} />
        </ListItemIcon>
        <ListItemText primary="Joueurs maximum" />
        <Chip label={eventData.logisticsOptions.playersMaximum} variant="outlined" />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'user']} />
        </ListItemIcon>
        <ListItemText primary="Joueurs actuellement inscrits" />
        <Chip label={eventData.registrations.length} variant="outlined" />
      </ListItem>

      <Divider />

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'clock-rotate-left']} />
        </ListItemIcon>
        <ListItemText primary="Expérience attendue" />
      </ListItem>

      <ListItem>
        <SliderExperience disabled value={sliderExperienceValue} />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'face-grin-tears']} />
        </ListItemIcon>
        <ListItemText primary="Déconnade ou sérieux" />
      </ListItem>

      <ListItem>
        <SliderSeriousness disabled value={sliderSeriousnessValue} />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'book-open-reader']} />
        </ListItemIcon>
        <ListItemText primary="Narratif ou liberté" />
      </ListItem>

      <ListItem>
        <SliderFreedom disabled value={sliderFreedomValue} />
      </ListItem>
    </List>
  );
}
