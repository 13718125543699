export default function setJsonValueImmutable(jsonObject, jsonPath, value) {
  // Helper function to clone objects and arrays
  function clone(obj) {
    if (Array.isArray(obj)) {
      return obj.map(item => clone(item));
    } else if (obj && typeof obj === 'object') {
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = clone(obj[key]);
        return newObj;
      }, {});
    } else {
      return obj;
    }
  }

  // Clone the original object to avoid mutating it
  const newJsonObject = clone(jsonObject);

  // Split the path into keys
  const keys = jsonPath.split('.');
  let current = newJsonObject;

  // Traverse the path and create intermediate objects/arrays as needed
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];

    // If the key is an array index
    if (key.endsWith(']')) {
      const [arrKey, index] = key.slice(0, -1).split('[');
      if (!current[arrKey]) {
        current[arrKey] = [];
      }
      if (!current[arrKey][index]) {
        current[arrKey][index] = {};
      }
      current = current[arrKey][index];
    } else {
      // If the key is an object property
      if (!current[key]) {
        current[key] = {};
      }
      current = current[key];
    }
  }

  // Set the value at the final key
  const finalKey = keys[keys.length - 1];
  if (finalKey.endsWith(']')) {
    const [arrKey, index] = finalKey.slice(0, -1).split('[');
    if (!current[arrKey]) {
      current[arrKey] = [];
    }
    current[arrKey][index] = value;
  } else {
    current[finalKey] = value;
  }

  return newJsonObject;
}
