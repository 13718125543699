import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import BackendDataContext from './BackendDataContext';
import ConfigContext from './ConfigContext';
import FetchApiData from '../../components/FetchApiData';
import getDefaultBackendData from './getDefaultBackendData';
import getDefaultConfigData from './getDefaultConfigData';

export function AppContexts({ children }) {
  const [gamesLoading, setGamesLoading] = useState(true);
  const [seasonsLoading, setSeasonsLoading] = useState(true);
  const [isPreloading, setPreloading] = useState(true);
  const [games, setGames] = useState(getDefaultBackendData().games);
  const [seasons, setSeasons] = useState(getDefaultBackendData().seasons);
  const { enqueueSnackbar } = useSnackbar();

  const handleDataGames = useCallback((games) => (setGames(games)), []);
  const handleDataSeasons = useCallback((seasons) => (setSeasons(seasons)), []);
  const handleError = useCallback(
    () => {
      enqueueSnackbar(`Impossible de contacter le serveur, la page va se recharger.`, { variant: 'error' })
    },
    [enqueueSnackbar],
  );

  const handleGamesFinishedLoading = useCallback((isLoading) => {
    if (!isLoading) {
      setGamesLoading(false);
    }
  }, []);

  const handleSeasonsFinishedLoading = useCallback((isLoading) => {
    if (!isLoading) {
      setSeasonsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!gamesLoading && !seasonsLoading) {
      setPreloading(false);
    }
  }, [gamesLoading, seasonsLoading]);

  const backendData = {
    games,
    seasons,
    isPreloading,
  };

  return (
    <ConfigContext.Provider value={getDefaultConfigData()}>
      <FetchApiData onData={handleDataGames} onError={handleError} onLoad={handleGamesFinishedLoading} showLoader={false} target="/games">
        <FetchApiData onData={handleDataSeasons} onError={handleError} onLoad={handleSeasonsFinishedLoading} showLoader={false} target="/seasons">
          <BackendDataContext.Provider value={backendData}>
            {children}
          </BackendDataContext.Provider>
        </FetchApiData>
      </FetchApiData>
    </ConfigContext.Provider>
  );
}
