import Container from '@mui/material/Container';
import React from 'react';

import Accordion from 'components/Accordion';
import Subtitle from 'components/semantic/Subtitle';
import Title from 'components/semantic/Title';

export function HelpPage() {
  return (
    <Container>
      <Title>
        Aide
      </Title>

      <Subtitle>
        Maîtres du jeu
      </Subtitle>

      <Accordion title="Comment être Maître du jeu ?">
        Il ne s'agit que d'une option à régler dans le profil utilisateur. Contacter un administrateur pour obtenir ce rôle.
        <br />
        Pour ce qui est d'apprendre à être Maître du jeu, de nombreuses vidéos ou des articles permettent d'aider à démarrer. Il faut également demander conseil à d'autres Maîtres du jeu.
      </Accordion>

      <Accordion title="Quels jeux sont supportés ?">
        La liste des jeux supportés est disponible sur la page des jeux.
        <br />
        Pour ajouter un jeu à la liste, il faut contacter un administrateur.
      </Accordion>

      <Accordion title="Que faire faute de lieu pour jouer ?">
        Dans un premier temps, la plate-forme vise à proposer une organisation rigoureuse pour assurer la tenue des parties. Il est nécessaire de disposer d'un lieu pour héberger la session.
        <br />
        C'est quelque chose qui pourra évoluer dans le futur, avec une notamment une option qui permettra de préciser si la session a lieu en ligne ou non.
      </Accordion>

      <Accordion title="Comment créer une session ?">
        Une fois connecté avec un compte qui a les permissions de maître du jeu, vous pouvez créer une session en cliquant sur le bouton "Créer une session" dans la page "Mes sessions".
        <br />
        Vous devrez alors renseigner les informations nécessaires pour la session, comme le jeu, la date, le lieu, etc. Veuiller à bien remplir l'ensemble des champs demandés dans les trois pages de formulaire.
      </Accordion>

      <Subtitle>
        Joueurs
      </Subtitle>

      <Accordion title="Comment s'inscrire à une session ?">
        Dans la page des événements, il faut naviguer le calendrier jusqu'à la session souhaitée. Cliquer sur la session permet d'avoir une fenêtre qui s'ouvre avec les détails. Un onglet dédié permet de gérer l'inscription.
        <br />
        Cela n'est possible que pour un utilisateur connecté.
      </Accordion>
    </Container>
  );
}
