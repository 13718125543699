import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';

import FetchApiData from 'components/FetchApiData';


export default function UserStatistics() {
  const [userStats, setUserStats] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleData = React.useCallback(
    (data) => {
      if (data.error) {
        enqueueSnackbar(`Une erreur est survenue (${data.error.message}).`, { variant: 'error' });
      } else {
        setUserStats(data);
      }
    },
    [enqueueSnackbar],
  );

  return (
    <FetchApiData
      method="GET"
      onData={handleData}
      target="/protected/me/stats"
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fas', 'chart-simple']} />
          </ListItemIcon>

          <ListItemText primary={`Sessions jouées : ${userStats.sessionsAttendedPast}`} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fas', 'chart-simple']} />
          </ListItemIcon>

          <ListItemText primary={`Sessions à jouer : ${userStats.sessionsAttendedFuture}`} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fas', 'chart-column']} />
          </ListItemIcon>

          <ListItemText primary={`Sessions organisées : ${userStats.sessionsOrganizedPast}`} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fas', 'chart-column']} />
          </ListItemIcon>

          <ListItemText primary={`Sessions en cours d'organisation : ${userStats.sessionsOrganizedFuture}`} />
        </ListItem>
      </List>
    </FetchApiData>
  );
}
