import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import React, { useCallback, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import EventDialogDescriptionTab from './EventDialogDescriptionTab';
import EventDialogOrganizationTab from './EventDialogOrganizationTab';
import EventDialogPlayerTab from './EventDialogPlayerTab';
import EventDialogSubscriptionTab from './EventDialogSubscriptionTab';
import FetchApiData from '../../FetchApiData';

export default function EventDialog({ id, name, onClose }) {
  const [eventData, setEventData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleData = useCallback(
    (data) => setEventData(data),
    [setEventData],
  );

  if (!id) return null;

  return (
    <Dialog
      open={!!id}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: '50vw' } }}
    >
      <DialogTitle>
        {name}
      </DialogTitle>

      <FetchApiData onData={handleData} target={`/protected/sessions/${id}`}>
        {!eventData && (
          <DialogContent>
            <DialogContentText>
              Chargement ...
            </DialogContentText>
          </DialogContent>
        )}

        {!!eventData && (
          <DialogContent>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                onChange={(e, v) => setSelectedTab(v)}
                scrollButtons="auto"
                allowScrollButtonsMobile
                value={selectedTab}
                variant="scrollable"
              >
                <Tab label="Description" />
                <Tab label="Organisation" />
                <Tab label="Joueurs" />
                <Tab label="Inscription" />
              </Tabs>
            </Box>

            <Paper sx={{ px: 2 }} hidden={selectedTab !== 0}>
              <EventDialogDescriptionTab eventData={eventData} />
            </Paper>
            <Paper sx={{ px: 2 }} hidden={selectedTab !== 1}>
              <EventDialogOrganizationTab eventData={eventData} />
            </Paper>
            <Paper sx={{ px: 2 }} hidden={selectedTab !== 2}>
              <EventDialogPlayerTab eventData={eventData} />
            </Paper>
            <Paper sx={{ px: 2 }} hidden={selectedTab !== 3}>
              <EventDialogSubscriptionTab eventData={eventData} onUpdate={onClose} />
            </Paper>
          </DialogContent>
        )}
      </FetchApiData>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
}
