import Box from '@mui/material/Box';
import React, { useEffect, useRef, useState } from 'react';

import './Loader.scss';

export default function Loader({
  enabled,
  minimumDelayBeforeHiding = 1000,
  minimumThresholdBeforeDisplay = 200,
}) {
  const [displayed, setDisplayed] = useState(false);

  const timeoutId = useRef(null);

  useEffect(() => {
    if (enabled && !displayed) {
      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        setDisplayed(true)
      }, minimumThresholdBeforeDisplay);
    }

    if (!enabled && !displayed && timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    if (!enabled && !displayed && !timeoutId.current) {
      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        setDisplayed(false)
      }, minimumDelayBeforeHiding);
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    }
  }, [displayed, enabled, minimumDelayBeforeHiding, minimumThresholdBeforeDisplay]);

  return (
    <Box className={`loaderContainer ${displayed ? 'visible' : 'hidden'}`}>
      <Box className="loader"></Box >
    </Box>
  );
}
