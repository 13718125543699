import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

import FetchApiData from 'components/FetchApiData';
import Quote from 'components/semantic/Quote';
import SessionRegisterPermissionGuard from 'layout/guards/SessionRegisterPermissionGuard';
import SubscriptionStatus from 'components/tables/SubscriptionStatus';
import Text from 'components/semantic/Text';

export default function EventDialogSubscriptionTab({ eventData, onUpdate }) {
  const [isLoading, setIsLoading] = useState(false);
  const [sendData, setSendData] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleData = useCallback(
    (data) => {
      setSendData(false);
      if (data.error) {
        enqueueSnackbar(data.error.message, { variant: 'error' });
      }
      else {
        onUpdate(true);
      }
    },
    [enqueueSnackbar, onUpdate],
  );

  const handleError = useCallback(
    (data) => {
      enqueueSnackbar(`Impossible de s'enregistrer.`, { variant: 'error' })
      setSendData(false);
    },
    [enqueueSnackbar],
  );

  const handleLoading = useCallback((loading) => {
    setIsLoading(loading);
  }, []);

  const handleAction = useCallback(() => {
    setSendData({ status: !eventData.isSubscribed ? 'register' : 'unregister' });
  }, [eventData.isSubscribed]);

  return (
    <Box sx={{ py: 2 }}>
      <SessionRegisterPermissionGuard reverse>
        Vous n'avez pas les permissions pour vous inscrire aux événements. Tenter de vous déconnecter et de vous reconnecter. Si le problème persiste, veuillez contacter un administrateur.
      </SessionRegisterPermissionGuard>

      <SessionRegisterPermissionGuard>
        {!!sendData && (
          <FetchApiData
            body={sendData}
            method="PUT"
            onData={handleData}
            onError={handleError}
            onLoad={handleLoading}
            target={`/protected/sessions/${eventData._id}`}
          />
        )}

        <Alert severity="warning" sx={{ my: 1 }}>
          L'inscription n'est pas unilatérale, elle doit être validée par le maître du jeu.
        </Alert>

        <Quote>
          Vous pouvez vérifier ici le statut de votre inscription à tout moment.
        </Quote>

        {!eventData.isSubscribed && (
          <Alert severity="info" sx={{ mb: 1 }}>
            Vous n'êtes actuellement pas inscrit à cet événement.
          </Alert>
        )}

        {!!eventData.isSubscribed && (
          <Box sx={{ my: 1, textAlign: 'center' }}>
            <SubscriptionStatus value={eventData.registration.status} />
          </Box>
        )}

        <Text>
          Deux semaines avant l'événement, vous recevrez des informations par mail, à savoir la confirmation du déroulement de la session.
        </Text>

        {!eventData.isDone && (
          <Button disabled={isLoading} fullWidth onClick={handleAction} variant="contained">
            {!eventData.isSubscribed && 'S\'inscrire'}
            {!!eventData.isSubscribed && 'Se désinscrire'}
          </Button>
        )}

        {!!eventData.isDone && (
          <Alert severity="info" sx={{ mt: 1 }}>
            Cet événement est terminé.
          </Alert>
        )}

        {!!eventData.registration && eventData.registration.status === 2 && (
          <List>
            <ListItem sx={{ fontWeight: 'bold', justifyContent: 'center' }}>
              Joueurs inscrits
            </ListItem>
            {eventData.registrations.map((registration) => (
              <ListItem key={registration.userId._id}>
                {registration.userId.name}
              </ListItem>
            ))}
          </List>
        )}
      </SessionRegisterPermissionGuard>
    </Box>
  );
}
