import React from 'react';

import Scale from './Scale';

const possibleValues = [
  {
    value: 1,
    label: 'Déconnade',
  },
  {
    value: 2,
    label: 'Rigolade',
  },
  {
    value: 3,
    label: 'Attentif',
  },
  {
    value: 4,
    label: 'Concentré',
  },
  {
    value: 5,
    label: 'Sérieux',
  },
];

export default function SliderSeriousness({ onChange, sx, value }) {
  return (
    <Scale
      onChange={onChange}
      possibleValues={possibleValues}
      sx={sx}
      value={value}
    />
  )
}
