import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import React from 'react';
import { Link } from 'react-router-dom'

import Text from 'components/semantic/Text';
import Image from 'components/semantic/Image';

export default function GameCard(game) {
  const content = (
    <>
      <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', py: 1, height: 150 }}>
        <Image
          alt={game.name}
          src={game.links.picture || '/images/placeholder.webp'}
          style={{ borderRadius: 4, height: '90%', objectFit: 'contain' }}
        />
      </Box>

      <CardContent sx={{ textAlign: 'center' }}>
        <Text style={{ textAlign: 'center' }}>{game.name}</Text>
        <Chip label={game.genre.name} variant="outlined" />
      </CardContent>
    </>
  );

  return (
    <Card>
      {!game.links.website && content}
      {!!game.links.website && (
        <CardActionArea component={Link} to={game.links.website} target="_blank" rel="noopener noreferrer" >
          {content}
        </CardActionArea>
      )}
    </Card>
  )
}
