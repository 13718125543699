import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

import FetchApiData from 'components/FetchApiData';

export default function RegisterDialog({ open, onClose }) {
  const [action, setAction] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitEvent = useCallback(
    (event) => {
      event.preventDefault();
      setAction({ email: event.target.email.value });
    },
    [],
  );

  const handleData = useCallback(
    (data) => {
      setAction(false);
      if (data.error) {
        enqueueSnackbar(data.error.message, { variant: 'error' });
      }
      enqueueSnackbar('Compte créé avec succès.', { variant: 'success' });
      onClose();
    },
    [enqueueSnackbar, onClose],
  );

  const handleLoad = useCallback((loading) => setIsLoading(loading), []);

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmitEvent,
        sx: { width: '80%' }
      }}
    >
      {!!action && (
        <FetchApiData
          body={action}
          method="POST"
          onData={handleData}
          onLoad={handleLoad}
          target="/register"
        />
      )}

      <DialogTitle>
        Création de compte
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Pour créer un compte, il est nécessaire de renseigner une adresse mail.
          <br />
          Le compte devra être validé par un administrateur avant de pouvoir s'inscrire à une session.
        </DialogContentText>

        <TextField
          autoFocus
          disabled={isLoading}
          fullWidth
          label="Adresse mail"
          margin="dense"
          name="email"
          required
          type="email"
          variant="standard"
        />
      </DialogContent>

      <DialogActions>
        <Button variant="contained" type="submit" disabled={isLoading}>Valider</Button>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
}
