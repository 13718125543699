import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';

import FetchApiData from 'components/FetchApiData';
import SubscriptionsTable from 'components/tables/SubscriptionsTable';

export default function ManageSessionDialog({ data, onClose, onRefresh, open }) {
  const [updateRegistrationData, setUpdateRegistrationData] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = React.useCallback((registration, status) => {
    setUpdateRegistrationData({ sessionId: data._id, userId: registration.userId._id, status });
  }, [data._id]);

  const handleData = React.useCallback(
    (data) => {
      if (data.error) {
        enqueueSnackbar(data.error.message, { variant: 'error' });
      } else {
        setUpdateRegistrationData(false);
        onRefresh();
      }
    },
    [enqueueSnackbar, onRefresh],
  );

  const handleLoad = React.useCallback((loading) => setIsLoading(loading), []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: '50vw' } }}
    >
      <DialogTitle>
        Gérer les inscriptions d'une session
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {(!data.registrations || !data.registrations.length) && (
          <Alert severity="info" sx={{ m: 2 }}>
            Aucun joueur inscrit à la session.
          </Alert>
        )}
        {data.registrations && !!data.registrations.length && (
          <FetchApiData
            body={updateRegistrationData}
            method="PUT"
            onData={handleData}
            onLoad={handleLoad}
            target={updateRegistrationData ? '/protected/me/sessions' : null}
          >
            <Alert severity="info" sx={{ mx: 2, mt: 2 }}>
              Changer l'état d'une inscription enverra un email au joueur concerné.
              <br />
              Cette action ne peut pas être faite plus d'une fois par heure pour un même joueur.
            </Alert>
            <SubscriptionsTable onChange={handleChange} session={data} />
          </FetchApiData>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} onClick={onClose} variant="contained">
          Valider
        </Button>
        <Button disabled={isLoading} onClick={onClose} variant="outlined">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
