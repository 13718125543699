import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

import FetchApiData from '../../components/FetchApiData';
import { setConnectionStatus } from '../../lib/store/sessionSlice';
import { useSelector, useDispatch } from 'react-redux';

const defaultAction = { body: '', method: '', url: '' };

export default function LoginDialog({ open, onClose }) {
  const [action, setAction] = useState(defaultAction);
  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingToken, setWaitingToken] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isConnected = useSelector((state) => state.session.isConnected);
  const dispatch = useDispatch();

  const handleSubmitEvent = useCallback(
    (event) => {
      event.preventDefault();

      if (!isConnected) {
        if (!isWaitingToken) {
          setAction({
            body: { email: event.target.email.value },
            method: 'POST',
            url: '/login',
          });
        } else {
          setAction({
            body: { code: event.target.code.value },
            method: 'POST',
            url: '/validate',
          });
        }
      } else {
        setAction({
          method: 'GET',
          url: '/protected/logout',
        });
      }
    },
    [isConnected, isWaitingToken],
  );

  const handleBack = () => {
    setAction(defaultAction);
    setWaitingToken(false);
  };

  const handleData = useCallback(
    (data) => {
      setAction(defaultAction);
      switch (action.url) {
        case '/login':
          setWaitingToken(true);
          enqueueSnackbar('Code envoyé par mail.', { variant: 'success' });
          break;
        case '/protected/logout':
          enqueueSnackbar('Vous êtes déconnectés.', { variant: 'success' });
          dispatch(setConnectionStatus());
          onClose();
          break;
        case '/validate':
          if (data.error) {
            enqueueSnackbar(data.error.message, { variant: 'error' });
            break;
          }
          dispatch(setConnectionStatus(data));
          enqueueSnackbar('Vous êtes connectés.', { variant: 'success' });
          onClose();
          setWaitingToken(false)
          break;
        default: break;
      }
    },
    [action.url, dispatch, enqueueSnackbar, onClose],
  );

  const handleLoad = useCallback((loading) => setIsLoading(loading), []);

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmitEvent,
        sx: { width: '80%' }
      }}
    >
      {!!action.url && (
        <FetchApiData
          body={action.body}
          method={action.method}
          onData={handleData}
          onLoad={handleLoad}
          target={action.url}
        />
      )}

      <DialogTitle>
        {!isConnected && 'Connexion'}
        {!!isConnected && 'Déconnexion'}
      </DialogTitle>

      {!isConnected && !isWaitingToken && (
        <DialogContent>
          <DialogContentText>
            Il faut saisir une adresse email pour se connecter.
            <br />
            Un code sera envoyé par mail sur cette adresse, il faudra le saisir dans la fenêtre suivante pour valider la connexion.
          </DialogContentText>

          <TextField
            autoFocus
            disabled={isLoading}
            fullWidth
            label="Adresse mail"
            margin="dense"
            name="email"
            required
            type="email"
            variant="standard"
          />
        </DialogContent>
      )}

      {!isConnected && isWaitingToken && (
        <DialogContent>
          <DialogContentText>
            Saisir le code reçu par mail.
          </DialogContentText>

          <TextField
            autoFocus
            disabled={isLoading}
            fullWidth
            label="Code de validation"
            margin="dense"
            name="code"
            required
            type="text"
            variant="standard"
          />
        </DialogContent>
      )}

      {!!isConnected && (
        <DialogContent>
          <DialogContentText>
            Valider la déconnexion ?
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        {!!isWaitingToken && (
          <Button variant="outlined" onClick={handleBack} disabled={isLoading}>Retour</Button>
        )}
        <Button variant="contained" type="submit" disabled={isLoading}>Valider</Button>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
}
