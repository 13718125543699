import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

function AppLink({ children, disabled, ...rest }) {
  const handleClick = (event) => {
    if (disabled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
  return (
    <Link className={disabled ? 'disabled' : 'enabled'} {...rest} onClick={handleClick}>
      {children}
    </Link>
  );
}

const StyledAppLink = styled(AppLink)(({ disabled, theme }) => (
  disabled
    ? {
      color: 'gray',
      cursor: 'not-allowed',
      textDecoration: 'line-through',
    }
    : {}
));

export default StyledAppLink
