import React from 'react';

import Scale from './Scale';

const possibleValues = [
  {
    value: 1,
    label: 'Profane',
  },
  {
    value: 2,
    label: 'Débutant',
  },
  {
    value: 3,
    label: 'Intermédiaire',
  },
  {
    value: 4,
    label: 'Expérimenté',
  },
  {
    value: 5,
    label: 'Vétéran',
  },
];

export default function SliderExperience({ onChange, sx, value }) {
  return (
    <Scale
      onChange={onChange}
      possibleValues={possibleValues}
      sx={sx}
      value={value}
    />
  )
}
