import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import ConnectedGuard from 'layout/guards/ConnectedGuard';
import Link from 'components/semantic/Link';
import SessionManagePermissionGuard from 'layout/guards/SessionManagePermissionGuard';
import Text from 'components/semantic/Text';

export default function NavigationDrawer() {
  const session = useSelector((state) => state.session);

  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem sx={{ my: 1 }}>
          <Link to="/">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fas', 'home']} />
            </ListItemIcon>

            <Box sx={{ display: 'inline', ml: 1 }}>Accueil</Box>
          </Link>
        </ListItem>

        <ListItem sx={{ my: 1 }}>
          <Link to="/events">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fas', 'calendar-days']} />
            </ListItemIcon>

            <Box sx={{ display: 'inline', ml: 1 }}>Événements</Box>
          </Link>
        </ListItem>

        <ListItem sx={{ my: 1 }}>
          <Link to="/games">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fas', 'dice-d20']} />
            </ListItemIcon>

            <Box sx={{ display: 'inline', ml: 1 }}>Jeux</Box>
          </Link>
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem sx={{ justifyContent: 'center', my: 1 }}>
          <Text>Espace connecté</Text>
        </ListItem>

        <ConnectedGuard reverse>
          <ListItem sx={{ my: 1 }}>
            <Text>Accès restreint aux comptes connectés</Text>
          </ListItem>
        </ConnectedGuard>

        <ConnectedGuard>
          <ListItem sx={{ my: 1 }}>
            <Link disabled={!session.isConnected} to="/connected/user">
              <ListItemIcon>
                <FontAwesomeIcon icon={['fas', 'user']} />
              </ListItemIcon>

              <Box sx={{ display: 'inline', ml: 1 }}>Page utilisateur</Box>
            </Link>
          </ListItem>
        </ConnectedGuard>
      </List>

      <Divider />

      <List>
        <ListItem sx={{ justifyContent: 'center', my: 1 }}>
          <Text>Espace MJ</Text>
        </ListItem>

        <SessionManagePermissionGuard reverse>
          <ListItem sx={{ justifyContent: 'center', my: 1 }}>
            <Text>Accès restreint aux MJs</Text>
          </ListItem>

          <ListItem sx={{ my: 1 }}>
            <Text>Essayer de se reconnecter peut corriger les problèmes d'accès, autrement il faut contacter un administrateur</Text>
          </ListItem>
        </SessionManagePermissionGuard>

        <SessionManagePermissionGuard>
          <ListItem sx={{ my: 1 }}>
            <Link to="/connected/sessions">
              <ListItemIcon>
                <FontAwesomeIcon icon={['fas', 'users']} />
              </ListItemIcon>

              <Box sx={{ display: 'inline', ml: 1 }}>Mes sessions</Box>
            </Link>
          </ListItem>
        </SessionManagePermissionGuard>
      </List>

      <Divider />

      <List>
        <ListItem sx={{ justifyContent: 'center', my: 1 }}>
          <Text>Informations</Text>
        </ListItem>

        <ListItem sx={{ my: 1 }}>
          <Link to="/help">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fas', 'person-circle-question']} />
            </ListItemIcon>

            <Box sx={{ display: 'inline', ml: 1 }}>Aide</Box>
          </Link>
        </ListItem>

        <ListItem sx={{ my: 1 }}>
          <Link to="/about">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fas', 'file-circle-question']} />
            </ListItemIcon>

            <Box sx={{ display: 'inline', ml: 1 }}>A propos</Box>
          </Link>
        </ListItem>
      </List>
    </Box>
  );
}
