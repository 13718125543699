import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import Loader from './Loader';
import ConfigContext from '../layout/contexts/ConfigContext';
import { setConnectionStatus } from '../lib/store/sessionSlice';

const forgeConfig = (method) => {
  const fetchConfig = {
    cache: 'no-cache',
    // credentials: 'same-origin',
    credentials: 'include',
    method: method ?? 'GET',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers: {
      'Cache-control': 'no-cache',
      'Content-Type': 'application/json',
      'Pragma': 'no-cache',
    },
  };

  return fetchConfig;
}

export default function FetchApiData({
  body = false,
  children,
  method = 'GET',
  onData,
  onError,
  onLoad = () => { },
  retry = 0,
  showLoader = true,
  target,
  version = 0,
}) {
  const [retryCount, setRetryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const appConfig = useContext(ConfigContext);

  const callbacks = useRef({ onData, onError, onLoad });
  const fetchConfiguration = useRef(forgeConfig(method));

  useEffect(() => {
    const { onData, onError, onLoad } = callbacks.current;
    let abortController;
    if (target) {
      abortController = new AbortController();

      setIsLoading(true);
      onLoad(true);

      const configuration = { ...fetchConfiguration.current, signal: abortController.signal };

      if (body && !!Object.keys(body).length) {
        configuration.body = JSON.stringify(body);
      }

      fetch(`${appConfig.API_URL}${target}`, configuration)
        .then((response) => {
          if (!abortController.signal.aborted) {
            if (!response.ok) {
              if (response.status === 401) {
                dispatch(setConnectionStatus());
                enqueueSnackbar(`Vous avez été déconnecté (session expirée).`, { variant: 'warning' });
              }
            }

            const contentType = response.headers.get('content-type');
            const isJson = contentType.indexOf('application/json') !== -1;

            return isJson
              ? response.json()
              : response.text();
          }
        })
        .then((data) => {
          if (!abortController.signal.aborted) {
            onData(data);
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            if (onError) {
              onError(error);
            } else {
              enqueueSnackbar(`Une erreur est survenue (${error}).`, { variant: 'error' })
            }

            if (retry) {
              setTimeout(() => {
                if (abortController && !abortController.signal.aborted) {
                  setRetryCount(retryCount + 1);
                }
              }, retry * 1000);
            }
          }
        })
        .finally(() => {
          if (!abortController.signal.aborted) {
            setIsLoading(false);
            onLoad(false);
          }
        });
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [appConfig.API_URL, body, callbacks, dispatch, enqueueSnackbar, fetchConfiguration, retry, retryCount, target, version]);

  if (isLoading && showLoader) {
    return (<Loader enabled></Loader>);
  }

  return (children);
}
