import React, { useState } from 'react';
import { AppBar, Box, Button, Drawer, Toolbar, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import LoginDialog from '../../components/dialogs/LoginDialog';
import RegisterDialog from '../../components/dialogs/RegisterDialog';
import NavigationDrawer from './NavigationDrawer';

export default function NavigationBar({ disabled = false }) {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [loginDialogOpened, setLoginDialogOpened] = useState(false);
  const [registerDialogOpened, setRegisterDialogOpened] = useState(false);
  const isConnected = useSelector((state) => state.session.isConnected);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpened(open);
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography sx={{ flexGrow: 1 }}>
            <Button disabled={disabled} color="inherit" sx={{ flexGrow: 1 }} onClick={toggleDrawer(true)}>
              <FontAwesomeIcon icon={['fas', 'bars']} />
              <Box sx={{ ml: 2 }}>
                Rencontres JDR
              </Box>
            </Button>
          </Typography>

          {!isConnected && (
            <Button disabled={disabled} color="inherit" onClick={() => setRegisterDialogOpened(true)} sx={{ mr: 2 }}>
              Créer un compte
            </Button>
          )}

          <Button disabled={disabled} color="inherit" onClick={() => setLoginDialogOpened(true)}>
            {!isConnected && 'Connexion'}
            {!!isConnected && 'Déconnexion'}
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        onClose={toggleDrawer(false)}
        open={drawerOpened}
      >
        <NavigationDrawer />
      </Drawer>

      <LoginDialog
        open={loginDialogOpened}
        onClose={() => setLoginDialogOpened(false)}
      ></LoginDialog>
      <RegisterDialog
        open={registerDialogOpened}
        onClose={() => setRegisterDialogOpened(false)}
      ></RegisterDialog>
    </Box >
  );
}
