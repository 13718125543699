import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'authorization',
  initialState: {
    expiration: new Date(),
    isConnected: false,
    permissions: [],
  },
  reducers: {
    setConnectionStatus: (state, action) => {
      if (action.payload) {
        state.expiration = new Date(action.payload.expiration) ?? new Date();
        state.isConnected = true;
        state.permissions = action.payload.permissions ?? [];
      } else {
        state.expiration = new Date();
        state.isConnected = false;
        state.permissions = [];
      }
    },
  },
})

export const { setConnectionStatus } = sessionSlice.actions;

export default sessionSlice.reducer;
