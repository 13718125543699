import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';

import setJsonValueImmutable from 'lib/setJsonValueImmutable';
import FetchApiData from 'components/FetchApiData';

export default function UserProfile({ user }) {
  const [editionMode, setEditionMode] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [sendData, setSendData] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState(user);
  const { enqueueSnackbar } = useSnackbar();

  const handleEditionMode = () => {
    setEditionMode(!editionMode);
  };

  const setUserProfileValue = (key, value) => {
    setUserProfile(setJsonValueImmutable(userProfile, key, value));
  };

  const handleData = React.useCallback(
    (data) => {
      if (data.error) {
        enqueueSnackbar(`Une erreur est survenue (${data.error.message}).`, { variant: 'error' });
      } else {
        enqueueSnackbar('Mise à jour réussie.', { variant: 'success' });
        setEditionMode(false);
      }
      setSendData(false);
    },
    [enqueueSnackbar],
  );

  const handleError = React.useCallback(
    (error) => {
      enqueueSnackbar(`Une erreur est survenue (${error}).`, { variant: 'error' });
      setSendData(false);
    },
    [enqueueSnackbar],
  );

  const handleLoad = React.useCallback((loading) => setIsLoading(loading), []);

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'at']} />
        </ListItemIcon>

        <ListItemText primary={` Email : ${user.email}`} />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'font']} />
        </ListItemIcon>

        <TextField
          disabled={!editionMode || !!isLoading}
          fullWidth
          label="Nom"
          name="name"
          onChange={(event) => !!editionMode && setUserProfileValue(event.target.name, event.target.value)}
          value={userProfile.name}
          variant="standard"
        />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'phone']} />
        </ListItemIcon>

        <TextField
          disabled={!editionMode || !!isLoading}
          fullWidth
          label="Téléphone"
          name="phone"
          onChange={(event) => !!editionMode && setUserProfileValue(event.target.name, event.target.value)}
          value={userProfile.phone}
          variant="standard"
        />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'hourglass-half']} />
        </ListItemIcon>

        <TextField
          disabled={!editionMode || !!isLoading}
          fullWidth
          label="Âge"
          name="age"
          onChange={(event) => !!editionMode && setUserProfileValue(event.target.name, event.target.value)}
          type="number"
          value={userProfile.age || 0}
          variant="standard"
        />
      </ListItem>

      {user.socialNetworks?.map((socialNetwork, index) => (
        <ListItem key={index}>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fas', 'share-nodes']} />
          </ListItemIcon>

          <ListItemText primary={` Réseau : ${socialNetwork}`} />
        </ListItem>
      ))}

      {user.playerOptions && (
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fas', 'clock-rotate-left']} />
          </ListItemIcon>

          <TextField
            disabled={!editionMode || !!isLoading}
            fullWidth
            label="Années d'expérience en tant que joueur"
            name="playerOptions.experience"
            onChange={(event) => !!editionMode && setUserProfileValue(event.target.name, event.target.value)}
            type="number"
            value={userProfile.playerOptions.experience || 0}
            variant="standard"
          />
        </ListItem>
      )}

      {user.gameMasterOptions && (
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fas', 'stopwatch']} />
          </ListItemIcon>

          <TextField
            disabled={!editionMode || !!isLoading}
            fullWidth
            label="Années d'expérience en tant que maître du jeu"
            name="gameMasterOptions.experience"
            onChange={(event) => !!editionMode && setUserProfileValue(event.target.name, event.target.value)}
            type="number"
            value={userProfile.gameMasterOptions.experience || 0}
            variant="standard"
          />
        </ListItem>
      )}

      <Divider />

      {user.playerOptions && user.playerOptions.enabled && (
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fas', 'people-group']} />
          </ListItemIcon>

          <FormControlLabel
            control={<Checkbox name="allowedSpectators" checked={user.playerOptions.enabled} />}
            readOnly
            label="Permission de participer à des sessions"
            labelPlacement="end"
          />
        </ListItem>
      )}

      {user.gameMasterOptions && user.gameMasterOptions.enabled && (
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fas', 'people-line']} />
          </ListItemIcon>

          <FormControlLabel
            control={<Checkbox name="allowedSpectators" checked={user.gameMasterOptions.enabled} />}
            readOnly
            label="Permission d'animer des sessions"
            labelPlacement="end"
          />
        </ListItem>
      )}

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'calendar-plus']} />
        </ListItemIcon>

        <ListItemText primary={`Date de création : ${user.createdAt}`} />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'calendar-check']} />
        </ListItemIcon>

        <ListItemText primary={`Date de mise à jour : ${user.updatedAt}`} />
      </ListItem>

      <Divider />

      <ListItem>
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <Button color="primary" variant="outlined" onClick={handleEditionMode}>
            {!!editionMode && (<FontAwesomeIcon icon={['fas', 'cancel']} />)}
            {!editionMode && (<FontAwesomeIcon icon={['fas', 'edit']} />)}
            &nbsp;
            {!!editionMode && 'Fermer mode édition'}
            {!editionMode && 'Activer mode édition'}
          </Button>

          {!!editionMode && (
            <Button color="primary" variant="contained" onClick={() => setSendData(true)}>
              <FontAwesomeIcon icon={['fas', 'check']} />
              &nbsp;
              Valider les modifications
            </Button>
          )}

          {!!sendData && (
            <FetchApiData
              body={userProfile}
              method="PATCH"
              onData={handleData}
              onError={handleError}
              onLoad={handleLoad}
              target="/protected/me"
            />
          )}
        </Box>
      </ListItem>
    </List>
  );
}
