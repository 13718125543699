import React, { useCallback, useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import BackendDataContext from '../../../layout/contexts/BackendDataContext';

export default function StepDescription({ isLoading, onChange, sessionData }) {
  const backendData = useContext(BackendDataContext);

  const handleChange = useCallback(
    (event) => (onChange(event.target.name, event.target.value)),
    [onChange],
  );

  return (
    <>
      <TextField
        autoFocus
        disabled={isLoading}
        fullWidth
        label="Nom de la session"
        margin="dense"
        name="name"
        onChange={handleChange}
        required
        type="text"
        value={sessionData.name || ''}
        variant="outlined"
      />

      <TextField
        disabled={isLoading}
        fullWidth
        label="Description"
        margin="dense"
        name="description"
        onChange={handleChange}
        required
        type="text"
        value={sessionData.description || ''}
        variant="outlined"
      />

      <FormControl disabled={isLoading} fullWidth margin="dense" required>
        <InputLabel id="createSession-gameInput-label">Jeu</InputLabel>
        <Select
          label="Jeu"
          labelId="createSession-gameInput-label"
          name="gameId"
          onChange={handleChange}
          value={sessionData.gameId || ''}
        >
          {backendData.games.map((game) => (
            <MenuItem key={game.id} value={game.id}>{game.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
