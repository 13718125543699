import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

import store from './lib/store';
import overload from './lib/overload';
import { AppContexts } from './layout/contexts/AppContexts';
import { AppRouter } from './layout/router/AppRouter';

library.add(fas);
overload();

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <AppContexts>
          <AppRouter />
        </AppContexts>
      </SnackbarProvider>
    </Provider>
  );
}

export default App
