import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function EventDialogOrganizationTab({ eventData }) {
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'calendar-days']} />
        </ListItemIcon>
        <ListItemText primary="Saison" />
        {eventData.season.name}
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'hourglass-start']} />
        </ListItemIcon>
        <ListItemText primary="Début" />
        {moment(eventData.startDate).utc().format('LLL')}
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'hourglass-end']} />
        </ListItemIcon>
        <ListItemText primary="Fin" />
        {moment(eventData.endDate).utc().format('LLL')}
      </ListItem>

      <Divider />

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'wine-bottle']} />
        </ListItemIcon>
        <ListItemText primary="Alcool autorisé" />
        <Checkbox checked={eventData.logisticsOptions.allowedAlcohol} readOnly />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'pizza-slice']} />
        </ListItemIcon>
        <ListItemText primary="Nourriture autorisée" />
        <Checkbox checked={eventData.logisticsOptions.allowedFood} readOnly />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'binoculars']} />
        </ListItemIcon>
        <ListItemText primary="Spectateurs autorisés" />
        <Checkbox checked={eventData.logisticsOptions.allowedSpectators} readOnly />
      </ListItem>
    </List>
  );
}
