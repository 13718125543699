import React from 'react';
import { useSelector } from 'react-redux';

export default function ConnectedGuard({
  children,
  reverse = false,
}) {
  const isConnected = useSelector((state) => state.session.isConnected);
  const condition = reverse ? !isConnected : !!isConnected;

  return (
    <>
      {condition && children}
    </>
  );
}
