import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GameCardLink from 'components/Game/GameCardLink';

export default function EventDialogDescriptionTab({ eventData }) {
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'dice']} />
        </ListItemIcon>
        <ListItemText primary="Jeu" />
        <GameCardLink id={eventData.game._id || eventData.game} />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'quote-left']} />
        </ListItemIcon>
        <ListItemText primary={eventData.description} />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'person-dots-from-line']} />
        </ListItemIcon>
        <ListItemText primary="Maître du jeu" />
        {eventData.gameMaster.name}
        {!!eventData.isGameMaster && ' (Vous)'}
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'location-dot']} />
        </ListItemIcon>
        <ListItemText primary="Lieu" />
        {eventData.location}
      </ListItem>
    </List>
  );
}
