import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import BackendDataContext from './contexts/BackendDataContext';
import BottomBar from './elements/BottomBar';
import Loader from '../components/Loader';
import NavigationBar from './elements/NavigationBar';
import { ErrorPage } from 'pages/ErrorPage';
import { PageLayout } from './PageLayout';

export function AppLayout() {
  const backendData = useContext(BackendDataContext);
  const isReady = backendData.games.length && backendData.seasons.length;

  return (
    <Box>
      <NavigationBar disabled={!isReady} />
      <PageLayout>
        {!!backendData.isPreloading && (<Loader enabled></Loader>)}
        {!backendData.isPreloading && !isReady && (<ErrorPage code={503} message="Le service est indisponible" reload />)}
        {!backendData.isPreloading && !!isReady && (<Outlet />)}
      </PageLayout>
      <BottomBar />
    </Box>
  );
}
