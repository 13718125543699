import React from 'react';
import Chip from '@mui/material/Chip';

export default function SubscriptionStatus({ value }) {
  switch (value) {
    case 1:
      return <Chip color="warning" label="En attente" size="small" />;
    case 2:
      return <Chip color="success" label="Validé" size="small" />;
    case 3:
      return <Chip color="error" label="Refusé" size="small" />;
    default:
      return <Chip color="default" label="Inconnu" size="small" />;
  }
}
