import React, { useCallback, useState } from 'react';
import { Alert, Box, Container, Paper, Tab, Tabs } from '@mui/material';

import FetchApiData from 'components/FetchApiData';
import Title from 'components/semantic/Title';
import UserProfile from './user/UserProfile';
import UserSettings from './user/UserSettings';
import UserStatistics from './user/UserStatistics';

export function UserPage() {
  const [user, setUser] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);

  const handleData = useCallback(
    (data) => setUser(data),
    [setUser],
  );

  return (
    <Container>
      <Title>
        Page utilisateur
      </Title>

      <FetchApiData onData={handleData} target="/protected/me">
        {!user && (
          <Alert severity="error">
            Utilisateur introuvable.
          </Alert>
        )}

        {user && (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)}>
                <Tab label="Profil" />
                <Tab label="Paramètres" />
                <Tab label="Statistiques" />
              </Tabs>
            </Box>

            <Paper sx={{ px: 2 }} hidden={selectedTab !== 0}>
              <UserProfile user={user} />
            </Paper>
            <Paper sx={{ px: 2 }} hidden={selectedTab !== 1}>
              <UserSettings user={user} />
            </Paper>
            <Paper sx={{ px: 2 }} hidden={selectedTab !== 2}>
              <UserStatistics user={user} />
            </Paper>
          </>

        )}
      </FetchApiData>
    </Container>
  );
}
