import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import React, { useContext } from 'react';
import moment from 'moment';

import BackendDataContext from '../contexts/BackendDataContext';

export default function BottomBar() {
  const backendData = useContext(BackendDataContext);

  const ongoingSeason = backendData.seasons.find((season) => season.isOngoing);
  const incomingSeason = backendData.seasons.find((season) => season.isComing);

  const currentSeason = ongoingSeason || incomingSeason;
  const startDateFormatted = currentSeason && moment(currentSeason.startDate).format('DD/MM/YYYY')
  const endDateFormatted = currentSeason && moment(currentSeason.endDate).format('DD/MM/YYYY');

  return (
    <>
      {ongoingSeason && (
        <AppBar component="div" position="fixed" color="success" sx={{ top: 'auto', bottom: 0 }}>
          <Box sx={{ p: 1, textAlign: 'center' }}>
            {`Saison ${currentSeason.name} en cours ! Du ${startDateFormatted} au ${endDateFormatted}.`}
          </Box>
        </AppBar>
      )}

      {incomingSeason && (
        <AppBar component="div" position="fixed" color="warning" sx={{ top: 'auto', bottom: 0 }}>
          <Box sx={{ p: 1, textAlign: 'center' }}>
            {`Saison ${currentSeason.name} à venir ! Du ${startDateFormatted} au ${endDateFormatted}.`}
          </Box>
        </AppBar>
      )}
    </>
  );
}
