import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Stepper, Step, StepLabel } from '@mui/material';
import { useSnackbar } from 'notistack';

import FetchApiData from 'components/FetchApiData';
import StepDescription from './StepDescription';
import StepOrganization from './StepOrganization';
import StepPlayers from './StepPlayers';
import BackendDataContext from 'layout/contexts/BackendDataContext';

// @TODO relier les sessions entre elles
// @TODO gérer les inscriptions

const steps = [
  `Description de l'événement`,
  `Organisation et logistique`,
  `Joueurs et jeu`,
];

const requiredKeysToMoveForward = {
  0: ['name', 'description', 'gameId'],
  1: ['startDate', 'endDate', 'location', 'allowedSpectators', 'allowedFood', 'allowedAlcohol'],
};

const defaultValues = {
  allowedAlcohol: false,
  allowedFood: true,
  allowedSpectators: true,
  playersAgeMaximum: 99,
  playersAgeMinimum: 18,
  playersExperienceMaximum: 5,
  playersExperienceMinimum: 3,
  playersMaximum: 4,
  playersMinimum: 2,
  scaleFreedomMaximum: 5,
  scaleFreedomMinimum: 3,
  scaleSeriousnessMaximum: 5,
  scaleSeriousnessMinimum: 3,
};

const mapData = (domainData) => {
  if (!domainData || typeof domainData !== 'object' || !Object.keys(domainData).length) {
    return {};
  }

  return {
    allowedAlcohol: domainData.logisticsOptions.allowedAlcohol,
    allowedFood: domainData.logisticsOptions.allowedFood,
    allowedSpectators: domainData.logisticsOptions.allowedSpectators,
    description: domainData.description,
    endDate: domainData.endDate,
    gameId: domainData.game._id,
    id: domainData._id,
    location: domainData.location,
    name: domainData.name,
    playersAgeMaximum: domainData.logisticsOptions.playersAgeMaximum,
    playersAgeMinimum: domainData.logisticsOptions.playersAgeMinimum,
    playersExperienceMaximum: domainData.logisticsOptions.playersExperienceMaximum,
    playersExperienceMinimum: domainData.logisticsOptions.playersExperienceMinimum,
    playersMaximum: domainData.logisticsOptions.playersMaximum,
    playersMinimum: domainData.logisticsOptions.playersMinimum,
    scaleFreedomMaximum: domainData.logisticsOptions.scaleFreedomMaximum,
    scaleFreedomMinimum: domainData.logisticsOptions.scaleFreedomMinimum,
    scaleSeriousnessMaximum: domainData.logisticsOptions.scaleSeriousnessMaximum,
    scaleSeriousnessMinimum: domainData.logisticsOptions.scaleSeriousnessMinimum,
    seasonId: domainData.season._id,
    startDate: domainData.startDate,
  }
};

export default function EditSessionDialog({ data, onClose, open }) {
  const backendData = useContext(BackendDataContext);
  const incomingSeason = backendData.seasons.find((season) => (season.isComing));
  const ongoingSeason = backendData.seasons.find((season) => (season.isOngoing));
  const currentSeason = incomingSeason || ongoingSeason;
  const currentSeasonId = currentSeason ? currentSeason.id : '';

  const [step, setStep] = useState(0);
  const [sessionData, setSessionData] = useState(defaultValues);
  const [sendData, setSendData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSessionData({ ...defaultValues, ...mapData(data) });
    setStep(0);
  }, [data]);

  const handleChange = useCallback((name, value) => {
    setSessionData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handlePrevious = useCallback(() => setStep(step - 1), [step]);
  const handleNext = useCallback(() => {
    if (step === steps.length - 1) {
      setSendData({
        method: 'POST',
        target: `/protected/me/sessions`,
        data: {
          seasonId: currentSeasonId,
          ...sessionData,
        }
      });
    } else {
      setStep(step + 1);
    }
  }, [currentSeasonId, sessionData, step]);

  const handleData = useCallback(
    (response) => {
      if (response.error) {
        enqueueSnackbar(response.error.message, { variant: 'error' });
      }
      else {
        onClose(true);
      }
    },
    [enqueueSnackbar, onClose],
  );

  const handleLoading = useCallback((loading) => {
    if (!loading) {
      setSendData(false);
    }
    setIsLoading(loading);
  }, [setSendData]);

  const handleDelete = useCallback(() => {
    setSendData({
      method: 'DELETE',
      target: `/protected/me/sessions/${sessionData.id}`,
    });
  }, [sessionData.id]);

  const canMoveForward = requiredKeysToMoveForward[step]
    ? requiredKeysToMoveForward[step].every((key) => sessionData[key] !== undefined && sessionData[key] !== '')
    : true;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: '50vw' } }}
    >
      {!!sendData && (
        <FetchApiData
          body={sendData.data}
          method={sendData.method}
          onData={handleData}
          onLoad={handleLoading}
          target={sendData.target}
        />
      )}

      <DialogTitle>
        Gérer les détails d'une session
      </DialogTitle>

      {!currentSeason && !sessionData.id && (
        <Alert severity="error" sx={{ my: 1 }}>
          Pas de saison en cours ou à venir. Vous ne pouvez pas créer de session pour le moment.
        </Alert>
      )}

      {(!!currentSeason || sessionData.id) && (
        <DialogContent>
          <Alert severity="warning" sx={{ my: 1 }}>
            La plate-forme étant encore en phase de tests, les événements peuvent être altérés ou supprimés en cas d'abus, de manque d'informations ou pour des raisons techniques.
          </Alert>

          <Box sx={{ my: 2, width: '100%' }}>
            <Stepper activeStep={step} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Paper elevation={2} sx={{ p: 2 }}>
            {step === 0 && (<StepDescription isLoading={isLoading} onChange={handleChange} sessionData={sessionData} />)}
            {step === 1 && (<StepOrganization isLoading={isLoading} onChange={handleChange} sessionData={sessionData} />)}
            {step === 2 && (<StepPlayers isLoading={isLoading} onChange={handleChange} sessionData={sessionData} />)}
          </Paper>
        </DialogContent>
      )}

      <DialogActions>
        {!!currentSeason && step > 0 && (
          <Button color="secondary" disabled={isLoading} variant="contained" onClick={handlePrevious}>
            Retour
          </Button>
        )}
        {!!currentSeason && (
          <Button disabled={isLoading || !canMoveForward} variant="contained" onClick={handleNext}>
            {step === steps.length - 1 ? 'Valider' : 'Suivant'}
          </Button>
        )}
        <Button disabled={isLoading} variant="outlined" onClick={onClose}>
          Fermer
        </Button>
        {sessionData.id && (
          <Button color="error" disabled={isLoading} variant="contained" onClick={handleDelete}>
            Supprimer
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
