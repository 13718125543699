import React from 'react';
import { Box } from '@mui/material';

export function PageLayout({ children }) {
  return (
    <Box sx={{ m: 2, mb: 8 }}>
      {children}
    </Box>
  );
}
