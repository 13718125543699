import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function UserSettings({ user }) {
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'question']} />
        </ListItemIcon>

        <ListItemText primary={`Cette section n'est pas prête.`} />
      </ListItem>
    </List>
  );
}
