import sessionReducer from './sessionSlice'
import { configureStore } from '@reduxjs/toolkit'
import { debounce } from 'lodash';

const LOCAL_STORAGE_KEY = 'store';

export function loadState() {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export async function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
  } catch (e) {
    // Ignore
  }
}

const store = configureStore({
  reducer: {
    session: sessionReducer,
  },
  // here we restore the previously persisted state
  preloadedState: loadState(),
});

export default store;

// here we subscribe to the store changes
store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveState(store.getState());
  }, 800)
);
