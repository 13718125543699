import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GameCard from 'components/Game/GameCard';
import BackendDataContext from 'layout/contexts/BackendDataContext';

export default function GameCardLink({ id, name }) {
  const backendData = React.useContext(BackendDataContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const game = backendData.games.find((game) => game.id === id);

  return (
    <>
      {!game && (
        <Alert severity="warning">
          Jeu introuvable
        </Alert>
      )}
      {!!game && (
        <>
          <Button onClick={handleClick} variant="filled">
            <Box sx={{ mr: 2 }}>
              {name || game.name}
            </Box>
            <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
          </Button>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            id={id}
            onClose={handleClose}
            open={open}
          >
            <Box sx={{ p: 1 }}>
              <GameCard {...game} />
            </Box>
          </Popover>
        </>
      )}
    </>
  )
}
