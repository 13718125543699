import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SubscriptionsTableRow from 'components/tables/SubscriptionsTableRow';

export default function SubscriptionsTable({ onChange, session }) {
  const { registrations } = session;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center">État</TableCell>
          <TableCell align="center">Joueur</TableCell>
          {!session.isDone && (
            <TableCell align="center">Action</TableCell>
          )}
        </TableRow>
      </TableHead>

      <TableBody>
        {registrations.map((registration) => (
          <SubscriptionsTableRow
            key={registration.userId._id}
            onChange={onChange}
            readOnly={session.isDone}
            registration={registration}
          />
        ))}
      </TableBody>
    </Table>
  );
}
