import React, { useCallback } from 'react';
import { FormControlLabel, TextField } from '@mui/material';

import SliderExperience from '../../SliderExperience';
import SliderSeriousness from '../../SliderSeriousness';
import SliderFreedom from '../../SliderFreedom';

export default function StepPlayers({ isLoading, onChange, sessionData }) {
  const handleTextChange = useCallback(
    (event) => {
      if (!isLoading) {
        onChange(event.target.name, event.target.value);
      }
    },
    [isLoading, onChange],
  );

  const handleSliderChange = useCallback(
    (name, value) => {
      if (!isLoading) {
        onChange(`${name}Minimum`, value[0]);
        onChange(`${name}Maximum`, value[1]);
      }
    },
    [isLoading, onChange],
  );

  return (
    <>
      <TextField
        InputProps={{ inputProps: { max: 14, min: 1 } }}
        autoFocus
        disabled={isLoading}
        fullWidth
        label="Nombre de joueurs minimum"
        margin="dense"
        name="playersMinimum"
        onChange={handleTextChange}
        required
        type="number"
        value={sessionData.playersMinimum}
        variant="outlined"
      />

      <TextField
        InputProps={{ inputProps: { max: 15, min: 2 } }}
        disabled={isLoading}
        fullWidth
        label="Nombre de joueurs maximum"
        margin="dense"
        name="playersMaximum"
        onChange={handleTextChange}
        required
        type="number"
        value={sessionData.playersMaximum}
        variant="outlined"
      />

      <TextField
        InputProps={{ inputProps: { max: 99, min: 2 } }}
        autoFocus
        disabled={isLoading}
        fullWidth
        label="Age minimum"
        margin="dense"
        name="playersAgeMinimum"
        onChange={handleTextChange}
        required
        type="number"
        value={sessionData.playersAgeMinimum}
        variant="outlined"
      />

      <TextField
        InputProps={{ inputProps: { max: 99, min: 2 } }}
        disabled={isLoading}
        fullWidth
        label="Age maximum"
        margin="dense"
        name="playersAgeMaximum"
        onChange={handleTextChange}
        required
        type="number"
        value={sessionData.playersAgeMaximum}
        variant="outlined"
      />

      <FormControlLabel
        control={
          <SliderExperience
            onChange={(value) => handleSliderChange('playersExperience', value)}
            sx={{ ml: 4 }}
            value={[sessionData.playersExperienceMinimum, sessionData.playersExperienceMaximum]}
          />
        }
        disabled={isLoading}
        label="Expérience attendue"
        labelPlacement="start"
        sx={{ justifyContent: 'center', width: '100%' }}
      />

      <FormControlLabel
        control={
          <SliderSeriousness
            onChange={(value) => handleSliderChange('scaleSeriousness', value)}
            sx={{ ml: 4 }}
            value={[sessionData.scaleSeriousnessMinimum, sessionData.scaleSeriousnessMaximum]}
          />
        }
        disabled={isLoading}
        label="Échelle sérieux"
        labelPlacement="start"
        sx={{ justifyContent: 'center', width: '100%' }}
      />

      <FormControlLabel
        control={
          <SliderFreedom
            onChange={(value) => handleSliderChange('scaleFreedom', value)}
            sx={{ ml: 4 }}
            value={[sessionData.scaleFreedomMinimum, sessionData.scaleFreedomMaximum]}
          />
        }
        disabled={isLoading}
        label="Échelle narration"
        labelPlacement="start"
        sx={{ justifyContent: 'center', width: '100%' }}
      />
    </>
  );
}
