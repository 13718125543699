import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ErrorPage } from '../../pages/ErrorPage';

export default function RouterConnectedGuard() {
  const isConnected = useSelector((state) => state.session.isConnected);

  if (!isConnected) {
    return <ErrorPage code={401} message="Vous devez être connecté" />
  }

  return (<Outlet />);
}
