import styled from "@emotion/styled";

const Image = styled.img`
  border-radius: 8px;
  display: block;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.5));
  margin: 8px auto 16px;
  max-width: 50%;
  object-fit: cover;
  overflow: hidden;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

export default Image;
