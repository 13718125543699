import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import React, { useCallback, useContext, useState } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';

import BackendDataContext from 'layout/contexts/BackendDataContext';
import EditSessionDialog from 'components/dialogs/EditSessionDialog/EditSessionDialog';
import FetchApiData from 'components/FetchApiData';
import ManageSessionDialog from 'components/dialogs/ManageSessionDialog';
import SessionsTableBody from 'components/SessionsTable/SessionsTableBody';

export function SessionsPage() {
  const backendData = useContext(BackendDataContext);
  const incomingSeason = backendData.seasons.find((season) => (season.isComing));

  const [selectedSessionId, setSelectedSessionId] = useState('');
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [manageDialogOpened, setManageDialogOpened] = useState(false);
  // const [editDialogData, setEditDialogData] = useState(0);
  // const [manageDialogData, setManageDialogData] = useState(0);
  const [refreshData, setRefreshData] = useState(0);
  const [sessions, setSessions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleData = useCallback(
    (data) => {
      if (data.error) {
        enqueueSnackbar(data.error.message, { variant: 'error' });
      } else {
        setSessions(data.sessions);
      }
    },
    [enqueueSnackbar],
  );

  const handleError = useCallback(
    (data) => enqueueSnackbar(`Impossible de récupérer la liste des événements.`, { variant: 'warning' }),
    [enqueueSnackbar],
  );

  const handleRefresh = () => setRefreshData((prev) => prev + 1);

  const handleCreateSession = () => {
    setSelectedSessionId(0);
    setEditDialogOpened(true);
  };

  const handleEditSession = (session) => {
    setSelectedSessionId(session._id);
    setEditDialogOpened(true);
  };

  const handleManageSession = (session) => {
    setSelectedSessionId(session._id);
    setManageDialogOpened(true);
  }

  const handleEditDialogClose = (update) => {
    setEditDialogOpened(false);
    if (typeof update === "boolean" && update) {
      handleRefresh();
    }
  };

  const handleManageDialogClose = () => (setManageDialogOpened(false));

  const selectedSession = sessions.find((session) => (session._id === selectedSessionId));

  return (
    <Box>
      {!!selectedSessionId && (
        <EditSessionDialog
          data={selectedSession}
          onClose={handleEditDialogClose}
          open={editDialogOpened}
        />
      )}
      {!!selectedSessionId && (
        <ManageSessionDialog
          data={selectedSession}
          onClose={handleManageDialogClose}
          onRefresh={handleRefresh}
          open={manageDialogOpened}
        />
      )}

      <Box sx={{ alignItems: 'center', display: 'flex', gap: 4, mb: 2 }}>
        <Alert severity="warning" sx={{ flexGrow: 80, my: 1 }}>
          Pour des raisons de sécurité, vous êtes limité à la création de 5 sessions par saison pour l'instant. Si vous souhaitez en organiser davantage, contacter un administrateur.
        </Alert>

        <Box sx={{ flexGrow: 20, textAlign: 'right' }}>
          {!incomingSeason && (
            <Alert severity="error">
              Pas de saison à venir.
            </Alert>
          )}
          {!!incomingSeason && (
            <Button variant="outlined" onClick={handleCreateSession}>
              Créer une session
            </Button>
          )}
        </Box>
      </Box>

      <FetchApiData
        onData={handleData}
        onError={handleError}
        target="/protected/me/sessions"
        version={refreshData}
      >
        <TableContainer component={Paper} elevation={4}>
          <Table className="sessionsTable">
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell align="center">Jeu</TableCell>
                <TableCell align="center">Saison</TableCell>
                <TableCell align="center">Début</TableCell>
                <TableCell align="center">Fin</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>

            <SessionsTableBody
              onEdit={handleEditSession}
              onManage={handleManageSession}
              sessions={sessions.filter((session) => new Date(session.endDate) > new Date())}
              title="Sessions à venir"
            />
            <SessionsTableBody
              onManage={handleManageSession}
              sessions={sessions.filter((session) => new Date(session.endDate) < new Date())}
              title="Sessions passées"
            />
          </Table>
        </TableContainer>
      </FetchApiData>
    </Box>
  );
}
