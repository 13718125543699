import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import BackendDataContext from 'layout/contexts/BackendDataContext';
import GameCard from 'components/Game/GameCard';
import Title from 'components/semantic/Title';

export function GamesPage() {
  const backendData = React.useContext(BackendDataContext);

  const published = [];
  const brews = [];

  backendData.games.forEach((game) => {
    if (game.homeMade) {
      brews.push(game);
    } else {
      published.push(game);
    }
  });

  return (
    <>
      <Title>
        Les jeux publiés
      </Title>

      <Paper elevation={0} sx={{ p: 2, mb: 4 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
          {published.map((game) => (
            <Box key={game.id} sx={{ minWidth: 'max(calc(calc(100% - 32px) / 3), 300px)' }}>
              <GameCard {...game} />
            </Box>
          ))}
        </Box>
      </Paper>

      <Title>
        Les jeux "maison"
      </Title>

      <Paper elevation={0} sx={{ p: 2, mb: 4 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
          {brews.map((game) => (
            <Box key={game.id} sx={{ minWidth: 'max(calc(calc(100% - 32px) / 3), 300px)' }}>
              <GameCard {...game} />
            </Box>
          ))}
        </Box>
      </Paper>
    </>
  );
}
