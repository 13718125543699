import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import RouterConnectedGuard from 'layout/guards/RouterConnectedGuard';
import { AboutPage } from 'pages/AboutPage';
import { AppLayout } from 'layout/AppLayout';
import { ErrorPage } from 'pages/ErrorPage';
import { EventsPage } from 'pages/EventsPage';
import { GamesPage } from 'pages/GamesPage';
import { HelpPage } from 'pages/HelpPage';
import { HomePage } from 'pages/HomePage';
import { SessionsPage } from 'pages/connected/SessionsPage';
import { UserPage } from 'pages/connected/UserPage';

export function AppRoutes() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<HomePage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="events" element={<EventsPage />} />
        <Route path="games" element={<GamesPage />} />
        <Route path="help" element={<HelpPage />} />

        {/* Set up a connected path with a layout to handle if not connected to avoid repetition like a "guard" */}
        <Route path="/connected" element={<RouterConnectedGuard />}>
          <Route path="user" element={<UserPage />} />
          <Route path="sessions" element={<SessionsPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
}
