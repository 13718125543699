import React, { useCallback, useState } from 'react';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import CalendarView from 'components/CalendarView';
import EventDialog from 'components/dialogs/EventDialog/EventDialog';
import FetchApiData from 'components/FetchApiData';

moment.locale('fr');

export function EventsPage() {
  const [version, setVersion] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [eventList, setEventList] = useState([]);
  const isConnected = useSelector((state) => state.session.isConnected);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedEventId, selectedEventName] = selectedEvent;

  const handleData = useCallback(
    (data) => {
      setEventList(data.map((session) => ({
        ...session,
        start: moment(session.start).utc().toDate().utc(),
        end: moment(session.end).utc().toDate().utc(),
      })));
      enqueueSnackbar(`Liste des événements récupérés.`, { variant: 'success' });
    },
    [enqueueSnackbar, setEventList],
  );

  const handleError = useCallback(
    (data) => enqueueSnackbar(`Impossible de récupérer la liste des événements.`, { variant: 'warning' }),
    [enqueueSnackbar],
  );

  const handleSelectEvent = (data) => {
    if (isConnected) {
      setSelectedEvent(data);
    } else {
      enqueueSnackbar(`Vous devez être connecté pour consulter les détails d'un événement.`, { variant: 'warning' });
    }
  };

  const handleCloseDialog = (shouldRefresh) => {
    setSelectedEvent([]);
    if (shouldRefresh && typeof shouldRefresh === 'boolean') {
      setVersion(version + 1);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', lg: 'row' } }}>
        {!isConnected && (<Alert severity="info">Vous devez être connecté pour consulter les détails d'un événement.</Alert>)}
        {!!isConnected && (
          <>
            <Alert severity="info" sx={{ flex: 1 }}>
              Les événements s'affichent par défaut en bleu.
            </Alert>
            <Alert color="secondary" severity="info" sx={{ flex: 1 }}>
              Les événements dont vous être le MJ s'affichent en violet.
            </Alert>
            <Alert severity="warning" sx={{ flex: 1 }}>
              Les événements où votre inscription est en attente s'affichent en jaune.
            </Alert>
            <Alert severity="success" sx={{ flex: 1 }}>
              Les événements où votre inscription est validée s'affichent en vert.
            </Alert>
          </>
        )}
      </Box>

      <FetchApiData onData={handleData} onError={handleError} retry={10} target="/sessions" version={version}>
        {!eventList.length && (
          <Alert severity="warning">Aucun événement disponible. Le calendrier se chargera lorsqu'il y aura des événements.</Alert>
        )}
        {!!eventList.length && (
          <Box sx={{ my: 1 }}>
            <CalendarView
              events={eventList}
              onSelect={handleSelectEvent}
            />
            <EventDialog
              id={selectedEventId}
              name={selectedEventName}
              onClose={handleCloseDialog}
            ></EventDialog>
          </Box >
        )}
      </FetchApiData>
    </>
  );
}
