import React from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import styles from './CalendarView.module.css';

moment.locale('fr');
const localizer = momentLocalizer(moment)

const calendarEventStyle = (currentView, event, start, end, isSelected, ...rest) => {
  let modifier = '';

  if (event.isGameMaster) {
    modifier = 'gameMaster';
  }
  if (event.registration && event.registration.status === 1) {
    modifier = 'pending';
  }
  if (event.registration && event.registration.status === 2) {
    modifier = 'validated';
  }

  return {
    className: styles[`${currentView}-${modifier}`]
  };
};

export default function CalendarView({ events, onSelect }) {
  const [currentView, setCurrentView] = React.useState('month');

  const now = moment().utc();
  const lastDate = events.reduce((acc, event) => event.end > acc ? event.end : acc, now);
  const firstDate = events.reduce((acc, event) => event.start < acc && event.start > now ? event.start : acc, lastDate);

  return (
    <Calendar
      defaultDate={firstDate}
      eventPropGetter={(...rest) => calendarEventStyle(currentView, ...rest)}
      events={events}
      localizer={localizer}
      max={moment().utc().set({ hour: 23, minute: 59, second: 59, millisecond: 0 })}
      messages={{
        day: "Jour",
        month: "Mois",
        next: "Suivant",
        previous: "Précédent",
        today: "Aujourd'hui",
        week: "Semaine",
      }}
      min={moment().utc().set({ hour: 8, minute: 0, second: 0, millisecond: 0 })}
      onSelectEvent={(event) => onSelect([event.resource, event.title])}
      onView={(view) => setCurrentView(view)}
      showAllEvents={true}
      style={{ height: '80vh' }}
      views={['month', 'week', 'agenda']}
    />
  );
}
