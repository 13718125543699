import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import ConfigContext from '../contexts/ConfigContext';

export function AppRouter() {
  const configuration = useContext(ConfigContext);
  return (
    <BrowserRouter basename={configuration.BASENAME}>
      <AppRoutes />
    </BrowserRouter>
  );
}
