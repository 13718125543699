import React from 'react';

import Scale from './Scale';

const possibleValues = [
  {
    value: 1,
    label: 'Narratif',
  },
  {
    value: 2,
    label: 'Dirigé',
  },
  {
    value: 3,
    label: 'Équilibré',
  },
  {
    value: 4,
    label: 'Aidé',
  },
  {
    value: 5,
    label: 'Liberté',
  },
];

export default function SliderFreedom({ onChange, sx, value }) {
  return (
    <Scale
      onChange={onChange}
      possibleValues={possibleValues}
      sx={sx}
      value={value}
    />
  )
}
