import React, { useCallback } from 'react';
import { Box, Slider } from '@mui/material';

export default function Scale({
  disabled,
  max = 5,
  min = 1,
  onChange = () => { },
  possibleValues,
  sx,
  value,
}) {
  const handleChange = useCallback(
    (event, value) => {
      if (!disabled) {
        onChange(value);
      }
    },
    [disabled, onChange],
  );

  return (
    <Box sx={{ mx: 3, width: '100%', ...(sx || {}) }}>
      <Slider
        disabled={disabled}
        marks={possibleValues}
        max={max}
        min={min}
        onChange={handleChange}
        step={null}
        value={value}
        valueLabelDisplay="auto"
      />
    </Box>
  )
}
