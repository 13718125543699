import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SubscriptionStatus from 'components/tables/SubscriptionStatus';

export default function SubscriptionsTableRow({ onChange, readOnly, registration }) {
  const { status } = registration;

  const handleAccept = React.useCallback(() => {
    onChange(registration, 2);
  }, [onChange, registration]);

  const handleReject = React.useCallback(() => {
    onChange(registration, 3);
  }, [onChange, registration]);


  return (
    <TableRow>
      <TableCell align="center">
        <SubscriptionStatus value={status} />
      </TableCell>

      <TableCell>
        {registration.userId.name}
      </TableCell>

      <TableCell>
        {!readOnly && (
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'right' }}>
            <IconButton color="success" onClick={handleAccept}>
              <FontAwesomeIcon icon={['fas', 'check']} />
            </IconButton>
            <IconButton color="error" onClick={handleReject}>
              <FontAwesomeIcon icon={['fas', 'ban']} />
            </IconButton>
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
}
