import React from 'react';
import { Button, Container } from '@mui/material';

import Image from 'components/semantic/Image';
import Title from 'components/semantic/Title';

export function ErrorPage({ code = 404, message = 'Page introuvable', reload = false }) {
  return (
    <>
      <Title>
        {`Erreur ${code} - ${message}`}
      </Title>

      <Image src="/images/404.webp" alt="Page introuvable" />

      {reload && (
        <Container>
          <Button fullWidth onClick={() => window.location.reload()} variant="contained">
            Recharger la page
          </Button>
        </Container>
      )}
    </>
  );
}
