import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import SessionsTableRow from 'components/SessionsTable/SessionsTableRow';

export default function SessionsTableBody({ onEdit, onManage, sessions, title }) {
  return (
    <TableBody>
      <TableRow className="divider">
        <TableCell align="center" colSpan={6}>
          {title}
        </TableCell>
      </TableRow>
      {sessions.map((session, index) => (
        <SessionsTableRow
          className={index % 2 ? 'even' : 'odd'}
          key={session._id}
          onEdit={onEdit}
          onManage={onManage}
          session={session}
        />
      ))}
    </TableBody>
  );
}
