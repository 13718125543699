import React from 'react';

import PermissionGuard from 'layout/guards/PermissionGuard';
import permissions from 'lib/permissions';

export default function SessionRegisterPermissionGuard(props) {
  return (<PermissionGuard
    {...props}
    permission={permissions.SESSIONS.REGISTER}
  />);
}
