import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export default function SessionsTableRow({ className, onEdit, onManage, session }) {
  return (
    <TableRow className={className}>
      <TableCell>{session.name}</TableCell>
      <TableCell align="center">{session.game.name}</TableCell>
      <TableCell align="center">{session.season.name}</TableCell>
      <TableCell align="center">{session.startDate}</TableCell>
      <TableCell align="center">{session.endDate}</TableCell>
      <TableCell align="center">
        <Box sx={{ display: 'flex', gap: 2 }}>
          {!!onEdit && (
            <Button fullWidth onClick={() => onEdit(session)} variant="contained">
              Modifier
            </Button>
          )}

          {!!onManage && (
            <Button fullWidth onClick={() => onManage(session)} variant="outlined">
              Inscriptions
              <Chip color="primary" label={session.registrations.length} size="small" sx={{ ml: 1 }} />
            </Button>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}
