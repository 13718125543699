import React from 'react';
import { Container, List, ListItem, ListItemText, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Image from 'components/semantic/Image';
import Quote from 'components/semantic/Quote';
import Text from 'components/semantic/Text';
import Title from 'components/semantic/Title';

export function HomePage() {
  return (
    <Container>
      <Title>
        Bienvenue sur les rencontres JDR !
      </Title>

      <Text>
        Cette plate-forme vise à permettre aux rôlistes de partager cette passion.
      </Text>

      <Text>
        Pendant les périodes de vacances et durant 2 mois, des sessions de jeu de rôle seront animées par plusieurs Maître du jeu sur divers jeux. Le principe est de proposer un calendrier de parties ouvertes pour permettre à chacun de découvrir de nouveaux univers et de nouvelles personnes.
      </Text>

      <Image src="/images/home.webp" alt="People playing game" />

      <Paper elevation={4} sx={{ mb: 4 }}>
        <List>
          <ListItem>
            <FontAwesomeIcon icon={['fas', 'dice-d20']} />&nbsp;
            <ListItemText disableTypography primary="Profanes : venez découvrir ce qu'est le jeu de rôle !" />
          </ListItem>

          <ListItem>
            <FontAwesomeIcon icon={['fas', 'dice-d20']} />&nbsp;
            <ListItemText disableTypography primary="Joueur connaisseur : découvrez de nouveaux Maître de jeu, de nouveaux jeux et de nouveaux univers." />
          </ListItem>

          <ListItem>
            <FontAwesomeIcon icon={['fas', 'dice-d20']} />&nbsp;
            <ListItemText disableTypography primary="Maître du jeu : partagez votre expérience avec d'autres passionnés et découvrez de nouveaux jeux. C'est aussi l'occasion de se faire des contacts et de former des groupes réguliers pour des campagnes de longue haleine !" />
          </ListItem>
        </List>
      </Paper>

      <Quote>
        {`Au gré des rencontres, l'on s'aperçoit que de nombreuses personnes pratiquent le jeu de rôle, mais chacun avec son groupe et dans son coin.
Ce projet vise donc à permettre à tous, passionnés ou profanes, de découvrir, partager et échanger sur cette merveilleuse activité.`}
      </Quote>
    </Container>
  );
}
