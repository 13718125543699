import styled from "@emotion/styled";

const Title = styled.h1`
  color: #BF4F74;
  font-size: 2.4em;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1.6em;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 1.4em;
    line-height: 1.3em;
  }
`;

export default Title;
