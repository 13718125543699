import React, { useContext } from 'react';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Checkbox, FormControlLabel, FormControl, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import BackendDataContext from '../../../layout/contexts/BackendDataContext';


export default function StepOrganization({ isLoading, onChange, sessionData }) {
  const backendData = useContext(BackendDataContext);
  const incomingSeason = backendData.seasons.find((season) => (season.isComing));

  return (
    <>
      <TextField
        autoFocus
        fullWidth
        disabled={isLoading}
        label="Lieu de la session de jeu"
        margin="dense"
        name="location"
        onChange={(event) => onChange(event.target.name, event.target.value)}
        required
        type="text"
        value={sessionData.location || ''}
        variant="outlined"
      />

      <FormControl fullWidth margin="dense" required>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          <DateTimePicker
            ampm={false}
            disabled={isLoading}
            fullWidth
            label="Date et heure de début"
            maxDate={moment(incomingSeason.endDate).utc()}
            minDate={moment(incomingSeason.startDate).utc()}
            minutesStep={5}
            name="startDate"
            onChange={(event) => onChange('startDate', event.toISOString())}
            required
            value={moment(sessionData.startDate || incomingSeason.startDate).utc()}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
            }}
          />
        </LocalizationProvider>
      </FormControl>

      <FormControl fullWidth margin="dense" required>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          <DateTimePicker
            ampm={false}
            disabled={isLoading}
            fullWidth
            label="Date et heure de fin"
            maxDate={moment(incomingSeason.endDate).utc()}
            minDate={moment(incomingSeason.startDate).utc()}
            minutesStep={5}
            name="endDate"
            onChange={(event) => onChange('endDate', event.toISOString())}
            required
            value={moment(sessionData.endDate || incomingSeason.startDate).utc()}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
            }}
          />
        </LocalizationProvider>
      </FormControl>

      <FormControlLabel
        control={<Checkbox name="allowedSpectators" checked={sessionData.allowedSpectators} onChange={(event) => onChange(event.target.name, event.target.checked)} />}
        disabled={isLoading}
        label="Spectateurs autorisé"
        labelPlacement="start"
        sx={{ justifyContent: 'center', width: '100%' }}
      />

      <FormControlLabel
        control={<Checkbox name="allowedFood" checked={sessionData.allowedFood} onChange={(event) => onChange(event.target.name, event.target.checked)} />}
        disabled={isLoading}
        label="Nourriture autorisé"
        labelPlacement="start"
        sx={{ justifyContent: 'center', width: '100%' }}
      />

      <FormControlLabel
        control={<Checkbox name="allowedAlcohol" checked={sessionData.allowedAlcohol} onChange={(event) => onChange(event.target.name, event.target.checked)} />}
        disabled={isLoading}
        label="Alcool autorisé"
        labelPlacement="start"
        sx={{ justifyContent: 'center', width: '100%' }}
      />
    </>
  );
}
