import React from 'react';

import PermissionGuard from 'layout/guards/PermissionGuard';
import permissions from 'lib/permissions';

export default function SessionManagePermissionGuard(props) {
  return (<PermissionGuard
    {...props}
    permission={permissions.SESSIONS.MANAGE}
  />);
}
