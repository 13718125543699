import styled from "@emotion/styled";

const Quote = styled.pre`
  border-left: solid 2px rgba(0, 0, 0, 0.5);
  box-shadow: -10px 0 15px -10px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 0.9em;
  margin-bottom: 10px;
  white-space: pre-line;
  padding: 8px 12px;
`;

export default Quote;
